import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import FaqCard from './Card';

function Partnership({pageInfo}) {
    // useEffect(() => {
    //     pageInfo('Partnership');
    // }, [])
    return ( 
        <>
            <Row className="justify-content-center text-center mb-md-3">
                <Col lg={12}>
                    <div className="section-title">
                        <h2>Partnership</h2>
                    </div>
                </Col>
            </Row>

            <Row>
                {DATA.map((item, index) => (
                    <Col sm={6} key={index} className="mb-md-4 mb-sm-3 mb-2">
                        <FaqCard head={item.title} body={item.content} />
                    </Col>
                ))}
            </Row>
        </>
     );
}

export default Partnership;

const DATA = [
    {
        title: 'Where can I view all the partnership?',
        content: 'HedgePay partnership can be found on our <a href="/">partnerships list</a>'
    },
    {
        title: 'How can I apply for partnership with HedgePay?',
        content: 'To apply a partnership with HedgePay, complete this <a href="/">Partnership Form</a>. We will revert back in 3-5 working days'
    },
    {
        title: 'What are the benefits of being a partner with HedgePay',
        content: 'Becoming a partner of HedgePay you can get more exposure and based on the agreement we agreed upon'
    },
    {
        title: 'Do I need to sign an agreement or MOU?',
        content: 'Yes, if required the attention on legal side, HedgePay will issue an agreement by our legal department. Hence it’s not for all the partnership'
    }
]